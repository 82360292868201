<template>
  <div>
    <b-alert variant="warning" show>이 화면의 데이터는 요청이 성공한 상태를 보여줍니다. 실제로 상품의 정보가 반영되었는지는 글로벌 홈페이지에서 확인해주세요.</b-alert>
    <b-card class="mb-2">
      <b-input-group class="mb-2">
        <b-input-group-prepend>
          <b-button variant="primary" @click="list" :disabled="busy.list">
            <i class="fa fa-search"></i> 검색
            <b-spinner class="ml-1" small v-if="busy.list"></b-spinner>
          </b-button>
        </b-input-group-prepend>
        <b-form-input id="search" type="text" placeholder="" v-model="form.list.search" @keypress.enter.prevent.stop="list" autocomplete="off"
                      v-focus></b-form-input>
      </b-input-group>

      <b-collapse id="collapse" v-model="collapse.detail">
        <form-options v-model="form.list" v-bind="{formOptions}">
          <template v-slot:slotEnd>
            <div class="flex-grow-0 mb-1 mr-2">
              <small>기본 상품수</small><br/>
              <b-form-input class="text-center w-70px" size="sm" title="한 번에 가져올 상품 수" v-model.number="form.list.limit" @keypress.enter="list()"></b-form-input>
            </div>
          </template>
        </form-options>
        <div class="fs-12 bold">
          검색 필드
        </div>
        <form-fields ref="fields" v-model="form.list.fields" :name="$options.name" :customFormFields.sync="customFormFields"
                     v-bind="{formFields, defaultFields}" @enter="list()"></form-fields>
        <form-inc-exc ref="incExc" v-model="form.list.incExc" :name="$options.name" :customFormIncExc.sync="customFormIncExc"
                      v-bind="{formIncExc, defaultIncExc}"></form-inc-exc>
      </b-collapse>

      <div class="mt-2 clearfix">
        <b-button class="" variant="primary" @click="list" :disabled="busy.list">
          검색
          <b-spinner class="ml-1" small v-if="busy.list"></b-spinner>
        </b-button>
        <b-button class="ml-1" variant="warning" @click="resetForm()">초기화</b-button>
        <b-button class="ml-1" variant="outline-success" v-b-toggle.collapse>상세검색조건</b-button>
        <div class="pull-right">
          <b-dropdown right variant="light" class="ml-1">
            <template #button-content>
              <i class="fa fa-copy"></i>
            </template>
            <b-dropdown-item @click="copy('gm_id')">GM ID</b-dropdown-item>
            <b-dropdown-item @click="copy('goods_no')">발란코드</b-dropdown-item>
            <b-dropdown-item @click="copy('goods_no', {separator: 'tab'})">발란코드(탭)</b-dropdown-item>
            <b-dropdown-item @click="copy('goods_no', {separator: 'comma'})">발란코드(콤마)</b-dropdown-item>
            <b-dropdown-item @click="copy('goods_no', {separator: 'quotes'})">발란코드(따옴표)</b-dropdown-item>
          </b-dropdown>
          <b-button class="ml-1" variant="success" @click="downXlsx()">XLSX</b-button>
        </div>
      </div>
    </b-card>

    <c-table id="shopifyGoods" :table-data="items.list" :fields="fields.list" :perPage.sync="perPage"
             :isBusy="busy.list" :getMoreBusy="busy.listmore" :hasMore="hasMore.list" @btn-clicked="btnAction"
             :caption="items.list.length + ' 개 데이터'" @get-more="list(true)">
    </c-table>
  </div>
</template>

<script>

import FormOptions from "../modules/FormOptions.vue";
import FormFields from "../modules/FormFields.vue";
import {formFieldsGen, formOptionsGen, formIncExcGen} from "./Shein/formData";
import Vue from "vue";
import FormIncExc from "../modules/FormIncExc.vue";
import {down} from '@/shared/impexp';

export default {
  name: 'SheinGoods',
  title: 'Shein 상품 관리',
  components: {FormIncExc, FormFields, FormOptions},
  data() {
    return {
      defaultForm: {
        list: {
          search: '',
          shelf_state: 'ALL',

          fields: {},
          incExc: {},
          sortKey: '_cdt',
          sortDir: 'desc',
          limit: 100,
          skip: 0,
        }
      },
      form: {
      },
      lastBody: {list: {}},
      item: {},
      items: {list: []},
      busy: {list: false, listmore: false, add: false, remove: false},
      hasMore: {list: false},
      ac: {list: null}, // abortController
      modal: {add: false},
      perPage: 20,

      collapse: {detail: true},
      formOptions: formOptionsGen(),
      formFields: formFieldsGen(),
      defaultFields: 'goods_no:eq,skc_title:eq',
      customFormFields: [],
      formIncExc: formIncExcGen(),
      defaultIncExc: 'goods_no,gm_id',
      customFormIncExc: [],

      fields: {
        list: [
          {key: 'selected', class: 'w-40px'},
          {key: '_img60', label: '이미지', class: 'text-center w-70px'},
          {key: 'html2', label: '발란코드<br/>skc', class: 'text-center'},
          {key: 'gm_id', label: 'GM ID', class: 'text-center'},
          {key: 'html1', label: '상품정보', class: 'mw-300px'},
          {key: 'shelf_state', label: '노출 상태', class: 'text-center'},
          {key: 'audit_state', label: '승인 상태', class: 'text-center'},
          {key: 'usdPrice', label: 'USD', class: 'text-right'},
          {key: 'tot_stock', label: '재고', class: 'text-center w-60px'},
          {key: '_cdt', label: '생성일시', class: 'text-center w-100px'},
          {key: '_mdt', label: '변경일시', class: 'text-center w-100px'},
          {
            key: '_actions', label: '기능', class: 'text-center w-250px', buttons: [
              {label: '재고', event: 'stock', variant: 'info', if: () => this.$R('DEV')},
              {label: '가격', event: 'price', variant: 'info', if: () => this.$R('DEV')},
              {label: '정보', event: 'goods', variant: 'info', if: () => this.$R('DEV')},
              {label: '노출 갱신', event: 'view', variant: 'success'},
              {label: '상세', event: 'json'},
            ]
          },
        ]
      },
    }
  },
  async created() {
    this.resetForm('list');
    this.list();
  },
  methods: {
    async list(more) {
      const form = this.form.list;
      const fields = this.$refs.fields && this.$refs.fields.makeFieldsQuery() || [];
      const incExc = this.$refs.incExc && this.$refs.incExc.makeIncExcQuery() || [];

      await this.$api.postTable(this, '/goods/shein/list', {...form, fields, incExc}, {more, fnAssign: this.assignTableData});
    },
    assignTableData(e) {
      const optionsHtml = `${e.opt.map(opt => `<span class="badge badge-light">${opt.Size} / no.${opt.option_no}</span>` +
          `<span class="badge badge-${opt.stock > 0 ? 'success' : 'secondary'}" title="판매 가능수량">${opt.stock}</span>`)}`;

      e.img = e.image_info.image_list && e.image_info.image_list[0].image_url || 'https://i.balaan.io/blank/noimg_goods_200.webp';
      e.html2 = `${e.goods_no}<br/>${e.skc_name}`;
      e.price = e.price.comma();
      e.usdPrice = e.price.comma();
      e.html1 = `${e.skc_title}<br/>${optionsHtml}`;
      e.tot_stock = e.opt.sum('stock');
      return e;
    },
    downAll() {

    },
    /* 좌측 버튼 부분 */
    resetForm(name) {
      Vue.set(this.form, name, this.$utils.clone(this.defaultForm[name]));
    },

    /* 우측 버튼 부분 */
    copy(col, {separator} = {}) {
      const selected = this.items.list;

      let res;
      if (separator === 'tab') {
        res = this.$utils.copyToClipboard(selected.map(e => e[col]).join('\t'));
      } else if (separator === 'comma') {
        res = this.$utils.copyToClipboard(selected.map(e => e[col]).join(','));
      } else if (separator === 'quotes') {
        res = this.$utils.copyToClipboard(selected.map(e => `'${e[col].toString().replace(/'/g, "\\'")}'`).join(',\n'));
      } else {
        res = this.$utils.copyToClipboard(selected.map(e => e[col]).join('\n'));
      }
      if (res) this.$alertTop(`복사되었습니다`);
    },

    downXlsx() {
      const headers = 'GM ID,발란코드,마스터 상품명(영문),재고,최초생성일시,최근변경일시'.split(',');
      let fields = 'gm_id,goods_no,skc_title,tot_stock,_cdt,_mdt'.split(',');
      down(this.items.list, headers, fields, `ShopifyGoods`, 'xlsx');
    },

    /* 리스트 부분 */
    async btnAction(row, event) {
      if (event === 'json') {
        this.$modal.show({title: 'JSON 보기', type: 'json', item: row.item});
      } else if (event === 'stock') {
        const j = await this.$api.postJson('/goods/shein/stock', {goodsNos: [row.item.goods_no]});
        if (j) {
          alert('재고 갱신 요청 되었습니다.');
        }
      } else if (event === 'price') {
        const j = await this.$api.postJson('/goods/shein/price', {goodsNos: [row.item.goods_no]});
        if (j) {
          alert('가격 갱신 요청 되었습니다.');
        }
      } else if (event === 'goods') {
        const j = await this.$api.postJson('/goods/shein/goods', {goodsNos: [row.item.goods_no]});
        if (j) {
          alert('상품 정보 갱신 요청 되었습니다.');
        }
      } else if (event === 'view') {
        const j = await this.$api.postJson('/goods/shein/shelfState', {goodsNos: [row.item.goods_no]});
        if (j) {
          alert('노출 상태 갱신 요청 되었습니다.');
        }
      }
    },
  }
}
</script>
