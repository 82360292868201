<template>
  <div>
    <b-tabs v-model="tabIndex">
      <b-tab title="상품관리">
        <shein-goods></shein-goods>
      </b-tab>
      <b-tab title="가격관리">
        <shein-pricing v-if="loaded[1]"></shein-pricing>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import SheinGoods from './SheinGoods.vue'
import SheinPricing from './SheinPricing.vue'

export default {
  name: 'Shein',
  title: 'Shein 관리',
  components: {SheinGoods, SheinPricing},
  data() {
    return {
      tabIndex: 0,
      loaded: {}, // {[tabIndex]: true} - lazy loading
    }
  },
  watch: {
    tabIndex(v) {
      this.loaded[v] = true;
    },
  },
}
</script>
