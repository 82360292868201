<template>
  <div>
    <div class="flex-row flex-wrap d-flex justify-content-start" v-if="incExc">
      <template v-for="f in customFormIncExc">
        <div v-if="!f.role || $R(f.role)" class="flex-grow-0 mb-1 mr-3" :style="{width: (f.width || 320) + 'px'}" :key="f.key">
          <small :title="f.key">{{f.name}}</small>
          <div class="flex-row flex-wrap d-flex">
            <b-form-textarea class="w-50" :rows="2" v-model="incExc[f.key].include" :placeholder="f.includePlaceholder || `포함할 ${f.name}`"></b-form-textarea>
            <b-form-textarea class="w-50" :rows="2" v-model="incExc[f.key].exclude" :placeholder="f.excludePlaceholder || `제외할 ${f.name}`"></b-form-textarea>
          </div>
        </div>
      </template>
      <div v-if="!hideSetting" class="flex-grow-0 mb-1 mr-3 pointer text-secondary d-flex align-items-center justify-content-center"
           @click="openFormIncExcModal" style="margin-top: 21px; width:47px; height:56px; border-radius: 5px; border: dashed 1px #ccc;" v-b-tooltip.bottom="'버튼을 눌러서 필드를 설정해 보세요'">
        <i class="fa fa-gear fa-2x"></i>
      </div>
    </div>

    <b-modal title="포함/제외 필드 설정" v-model="modal.incExc" ok-only ok-title="닫기" @hide="saveFormIncExc">
      <h6>선택된 필드별로 조건을 설정하여 검색할 수 있습니다.</h6>
      <div class="mb-2">
        <b-btn class="mr-1" size="sm" variant="success" @click="toggleIncExcAll(true)">전체선택</b-btn>
        <b-btn size="sm" variant="warning" @click="toggleIncExcAll(false)">전체해제</b-btn>
      </div>
      <b-form-checkbox-group v-model="_customFormIncExc" stacked>
        <template v-for="f in formIncExc">
          <b-form-checkbox v-if="!f.role || $R(f.role)" class="mr-0" :value="f" :key="f.key">
            {{f.name}} ({{f.key}})
          </b-form-checkbox>
        </template>
      </b-form-checkbox-group>
    </b-modal>
  </div>
</template>

<script>
import Vue from 'vue';

export default {
  name: 'FormIncExc',
  model: {prop: 'value', event: 'change'},
  props: {
    value: Object,
    name: String,
    defaultIncExc: {type: String, default: ''},
    formIncExc: Array,
    customFormIncExc: Array,
    hideSetting: {type: Boolean, default: false},
  },
  data() {
    return {
      modal: {incExc: false},
    };
  },
  created() {
    /*
      FormIncExc 적용시, 다음 사항을 체크한다.

      // list()
      const incExc = this.$refs.incExc && this.$refs.incExc.makeIncExcQuery() || [];

      // resetForm()
      const incExc = this.form.list.incExc;
      this.form.list = this.$utils.clone(this.defaultForm.list);
      Vue.set(this.form.list, 'incExc', incExc);
      this.$refs.incExc.resetFieldValues();
     */
    this.initFormIncExc();
  },
  computed: {
    incExc: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('change', v);
      }
    },
    _customFormIncExc: {
      get() {
        return this.customFormIncExc;
      },
      set(v) {
        this.$emit('update:customFormIncExc', v);
      }
    }
  },
  methods: {
    openFormIncExcModal() {
      this.modal.incExc = true;
    },
    toggleIncExcAll(check) {
      if (check) this._customFormIncExc = this.formIncExc.slice();
      else this._customFormIncExc.splice(0, this._customFormIncExc.length);
    },
    calcIncExc(inc, exc) {
      let include = inc ? inc.trim().split(/\r?\n/g).map(e => e.trim()) : [];
      let exclude = exc ? exc.trim().split(/\r?\n/g).map(e => e.trim()) : [];
      if (include.length && exclude.length) { // 둘 다 존재시 exclude 를 include 에서 제외
        const excludeMap = this.$utils.arr2map(exclude);
        include = include.filter(e => !excludeMap[e]);
        exclude = [];
      }
      return [include, exclude];
    },
    makeIncExcQuery() {
      return Object.entries(this.incExc).filter(([, obj]) => {
        // 값이 있을 때만 전달
        return obj.include.trim() || obj.exclude.trim();
      }).map(([key, obj]) => {
        const field = this.formIncExc.find(e => e.key === key);
        let [include, exclude] = this.calcIncExc(obj.include, obj.exclude);
        if (field.type === 'number') {
          include = include.map(e => e.split(/\D+/g)).flat().map(e => +e); // 123,134 처럼 한 줄에 들어오는 숫자 특별처리
          exclude = exclude.map(e => e.split(/\D+/g)).flat().map(e => +e); // 123,134 처럼 한 줄에 들어오는 숫자 특별처리
        }
        return {key, include, exclude, nested: field.nested};
      });
    },
    initFormIncExc() {
      for (const f of this.formIncExc) {
        Vue.set(this.incExc, f.key, {include: '', exclude: ''});
      }

      let incExc = localStorage.getItem(this.name + '.CustomFormIncExc');
      if (!incExc && this.defaultIncExc) { // default
        incExc = this.defaultIncExc;
      }
      const keyMap = this.$utils.arr2map(incExc.split(','));
      this._customFormIncExc.splice(0, this._customFormIncExc.length);
      for (const field of this.formIncExc.filter(e => keyMap[e.key])) {
        this._customFormIncExc.push(field);
      }
    },
    saveFormIncExc() {
      localStorage.setItem(this.name + '.CustomFormIncExc', this.customFormIncExc.map(e => e.key).join(','));
    },
    resetValues() {
      for (const v of Object.values(this.incExc)) {
        v.include = '';
        v.exclude = '';
      }
    },
  }
}
</script>

<style scoped>

</style>
