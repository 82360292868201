<template>
  <div>
    <b-row>
      <b-col cols="12" md="6">
        <h5>가격 결정 요소 설정</h5>
        <b-row class="mb-2">
          <b-col cols="6" xl="4">
            <small>정액 운영비</small>
            <i class="fa fa-question-circle ml-1" v-b-tooltip="'음수/양수 모두 가능'"></i><br/>
            <b-form inline>
              <b-form-input class="w-70px text-center mr-1 px-1" v-model.number="ready.opAmount" type="text"></b-form-input>
              원
            </b-form>
          </b-col>
          <b-col cols="6" xl="4">
            <small>정률 운영비 1</small>
            <i class="fa fa-question-circle ml-1" v-b-tooltip="'음수/양수 모두 가능'"></i><br/>
            <b-form inline>
              <b-form-input class="w-70px text-center mr-1 px-1" v-model.number="ready.opRate1" type="text"></b-form-input>
              %
            </b-form>
          </b-col>
          <b-col cols="6" xl="4">
            <small>정률 운영비 2</small>
            <i class="fa fa-question-circle ml-1" v-b-tooltip="'음수/양수 모두 가능'"></i><br/>
            <b-form inline>
              <b-form-input class="w-70px text-center mr-1 px-1" v-model.number="ready.opRate2" type="text"></b-form-input>
              %
            </b-form>
          </b-col>
          <b-col cols="6" xl="4">
            <small>신한은행 실시간 환율</small>
            <i class="fa fa-question-circle ml-1" v-b-tooltip="'현재의 신한 은행 전신환매입환율'"></i><br/>
            <b-form inline>
              <b-form-input class="w-70px text-center mr-1 px-1" v-model.number="usdFxRate" type="text" readonly></b-form-input>
              KRW/USD
            </b-form>
          </b-col>
          <b-col cols="6" xl="4">
            <small>USD 제한 금액</small>
            <i class="fa fa-question-circle ml-1" v-b-tooltip="'제한 금액 초과에 대해 송출하지 않습니다.'"></i><br/>
            <b-form inline>
              <b-form-input class="w-70px text-center mr-1 px-1" v-model.number="ready.usdLimitPrice" type="text"></b-form-input>
              $(USD)
            </b-form>
          </b-col>
        </b-row>
        <div class="mt-3">
          <b-btn variant="success" @click="save()" :disabled="busy.save">저장<b-spinner class="ml-1" small v-if="busy.save"></b-spinner></b-btn>
          <b-btn variant="outline-info" class="ml-1" @click="getPricing()" :disabled="busy.get">새로고침<b-spinner class="ml-1" small v-if="busy.get"></b-spinner></b-btn>
        </div>

        <h5 class="mt-3">시뮬레이션</h5>
        <small>KRW 가격</small>
        <b-form-input class="w-100px text-center mr-1 px-1" v-model.number="krwPrice" type="text"></b-form-input>
        <div>
          (({{ krwPrice }} / 1.1 ) + {{ ready.opAmount || 0 }} ) / ( 1 - ( {{ ready.opRate1 }} / 100 + {{ ready.opRate2 || 0 }} / 100 )) / {{ usdFxRate }}
        </div>
        <b-row>
          <b-col>
            <small>계산된 KRW 가격</small>
            <b-form-input class="w-100px text-center mr-1 px-1" v-model.number="krwBeforePrice" type="text" readonly></b-form-input>
          </b-col>
          <b-col>
            <small>USD 가격</small>
            <b-form-input class="w-100px text-center mr-1 px-1" v-model.number="usdPrice" type="text" readonly></b-form-input>
            <span class='text-danger'>{{ (usdPrice > ready.usdLimitPrice) && '제한 금액을 초과 하였습니다.' || '' }}</span>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12" md="6" class="border-left">
        <h5>현재의 가격 결정 요소</h5>
        <b-row class="mb-2">
          <b-col cols="6" xl="4">
            <small>정액 운영비</small>
            <i class="fa fa-question-circle ml-1" v-b-tooltip="'음수/양수 모두 가능'"></i><br/>
            <b-form inline>
              <b-form-input class="w-70px text-center mr-1 px-1" v-model.number="now.opAmount" type="text" disabled></b-form-input>
              원
            </b-form>
          </b-col>
          <b-col cols="6" xl="4">
            <small>정률 운영비 1</small>
            <i class="fa fa-question-circle ml-1" v-b-tooltip="'음수/양수 모두 가능'"></i><br/>
            <b-form inline>
              <b-form-input class="w-70px text-center mr-1 px-1" v-model.number="now.opRate1" type="text" disabled></b-form-input>
              %
            </b-form>
          </b-col>
          <b-col cols="6" xl="4">
            <small>정률 운영비 2</small>
            <i class="fa fa-question-circle ml-1" v-b-tooltip="'음수/양수 모두 가능'"></i><br/>
            <b-form inline>
              <b-form-input class="w-70px text-center mr-1 px-1" v-model.number="now.opRate2" type="text" disabled></b-form-input>
              %
            </b-form>
          </b-col>
          <b-col cols="6" xl="4">
            <small>신한은행 적용 환율</small>
            <i class="fa fa-question-circle ml-1" v-b-tooltip="'매일 오전 9시 55분, 신한 은행 전신환매입환율 기준 환율을 스냅샷 찍어서 24시간 동안 활용'"></i><br/>
            <b-form inline>
              <b-form-input class="w-60px text-center mr-1 px-1" v-model.number="now.exchange.usd" type="text" disabled></b-form-input>
<!--              <b-form-input class="w-60px text-center mr-1 px-1" v-model.number="usdFxRate" type="text" disabled></b-form-input>-->
              KRW/USD
            </b-form>
          </b-col>
          <b-col cols="6" xl="4">
            <small>USD 제한 금액</small>
            <i class="fa fa-question-circle ml-1" v-b-tooltip="'제한 금액 초과에 대해 송출하지 않습니다.'"></i><br/>
            <b-form inline>
              <b-form-input class="w-70px text-center mr-1 px-1" v-model.number="ready.usdLimitPrice" type="text" disabled></b-form-input>
              $(USD)
            </b-form>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<style scoped>
</style>

<script>

export default {
  name: 'SheinPricing',
  title: 'Shein 가격관리',
  data() {
    return {
      ready: {},
      now: {},
      usdFxRate: 1340,
      krwPrice: 1000000,
      krwBeforePrice: 0,
      usdPrice: 0,
      busy: {get: false, save: false}
    }
  },
  created() {
    this.getPricing();
    this.getFx();
  },
  watch: {
    ready: {
      deep: true,
      handler() {
        this.calcUsdPrice();
      },
    },
    krwPrice() {
      this.calcUsdPrice();
    }
  },
  methods: {
    async getPricing() {
      this.busy.get = true;
      const j = await this.$api.getJson(`/goods/shein/pricing`);
      this.busy.get = false;
      if (j) {
        this.ready = j.ready || {};
        this.now = j.now || {};
      }
    },
    getFx() {
      this.$api.getJson('/shop/shinhanFx').then(j => {
        if (!j.data.dataBody) { // 고시환율이 확정전 상태입니다. 고시환율 확정후 조회할 수 있습니다.
          setTimeout(this.getFx, 3000);
          return;
        }
        const usd = j.data.dataBody.R_RIBF3733_1.find(e => e['통화CODE'] === 'USD');
        this.usdFxRate = usd['전신환매입환율'];
      });
    },
    calcUsdPrice() {
      const r = this.ready;
      this.krwBeforePrice = Math.floor(((this.krwPrice / 1.1) + (r.opAmount || 0)) / (1 - ((r.opRate1 || 0) / 100 + (r.opRate2 || 0) / 100 )));
      this.usdPrice = Math.floor(this.krwBeforePrice / this.usdFxRate * 100) / 100;
    },
    async save() {
      this.busy.save = true;
      const j = await this.$api.postJson(`/goods/shein/setPricing`, this.ready);
      this.busy.save = false;

      if (j) {
        this.$alertTop('저장되었습니다. 다음 가격싱크 주기에 반영됩니다.');
      }
    },
  }
}
</script>
